<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          Fulfillment
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'outbound' }" >
          outbound
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'outbound' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>

    <!-- first floor -->
    <b-row>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="Order No"
          :title="baseInfo.invoiceNo"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="Status"
          :title="baseInfo.status"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="Order Type"
          :title="baseInfo.createdAt"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
        class="ll-p2"
      >
        <b-card
          class="ll-c ll-4"
          header="Planned Total SKU"
          :title="baseInfo.quantity"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
      >
        <b-card
          class="ll-c ll-4"
          header="Planned Total Qty"
          :title="baseInfo.totalAmount"
        >
        </b-card>
      </b-col>
      <b-col
        md="2"
      >
        <b-card
          class="ll-c ll-4"
          header="ASN Total Value"
          :title="baseInfo.totalAmount"
        >
        </b-card>
      </b-col>
    </b-row>
    <!-- second floor -->
    <b-row>
      <b-col
        md="6"
        lg="9"
        class="ll-p2"
      >
      <b-card
        no-body
        class="card-browser-states ll-r ll-6 ll-height"
        >
          <b-card-header>
            <b-card-title>Order Summary</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="Custom Name"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="Warehouse Code"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="Ref Order No (EC Platform)"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="Order Creation Time"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="OMS Order Creation Time"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="WH Handover Time"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="Shipment Term"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="Payment Method"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                class="ll-p2"
              >
                <b-form-group
                  label="Logistics Provider"
                  label-for="s"
                >
                  <b-form-input readonly/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        md="6"
        lg="3"
        class="ll-p2"
      >
        <b-card
          no-body
          class="border-primary ll-c ll-6 ll-mrg ll-height">
          <b-card-header>
            <b-card-title>Package</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="4" class="ll-imgBox">
                <b-img
                  :src="require('@/assets/images/imgs/2.jpg')"
                  height="auto"
                  width="100%"
                  class="mr-1"
                />
                <span class="ll-imgSpan">1/1</span>
              </b-col>
              <b-col lg="8">
                <div class="ll-box">
                  <label class="ll-lab">Tracking No</label>
                  <span class="ll-span">test</span>
                </div>
                <div class="ll-box">
                  <label class="ll-lab">Length</label>
                  <span class="ll-span"></span>
                </div>
                <div class="ll-box">
                  <label class="ll-lab">Width</label>
                  <span class="ll-span"></span>
                </div>
                <div class="ll-box">
                  <label class="ll-lab">Height</label>
                  <span class="ll-span"></span>
                </div>
                <div class="ll-box">
                  <label class="ll-lab">Gross Weight</label>
                  <span class="ll-span"></span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        lg="9"
        class="ll-p2"
      >
      <b-card
        no-body
        class="card-browser-states ll-r ll-6 ll-height1"
        >
          <b-card-header>
            <b-card-title>Inbound Item List</b-card-title>
          </b-card-header>
          <b-card-body class="ll-cb">
            <b-table :items="items" :fields="fields" :borderless='false' :hover="true">
            </b-table>
            <div class="d-flex justify-content-between flex-wrap ll-page" v-if="false">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="size"
                  :options="pageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ totalSize }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="page"
                  :total-rows="totalSize"
                  :per-page="size"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-card-body>
       </b-card>
      </b-col>
      <b-col
        md="12"
        lg="3"
        class="ll-p2"
      >
      <b-card
        no-body
        class="card-browser-states ll-r ll-6 ll-height1"
        >
          <b-card-header>
            <b-card-title>Receiver</b-card-title>
          </b-card-header>
          <b-card-body class="ll-cb">
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">Name</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">Address</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">Distinct</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">City</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">Province</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">Postal Code</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">Phone</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
            <div class="ll-bform ll-bfBox">
              <label class="ll-label">Email</label>
              <b-form-input
                v-model="account.billToName"
                readonly
                class="ll-inpBox"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BTable,
  BButton, BImg,
  BCardHeader, BCardTitle, BCardBody,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BTable,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormSelect,
    BPagination,
    BImg,
  },
  data() {
    return {
      pageOptions: [15, 25, 35],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      searchPID: '',
      borderless: true,
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'tkID', label: 'Item Code' },
        { key: 'status', label: 'Name' },
        { key: 'invoiceType', label: 'Description' },
        { key: 'feeType', label: 'Type' },
        { key: 'amount', label: 'Qty' },
        { key: 'date', label: 'Unit' },
        { key: 'qty', label: 'Unit Price' },
        { key: 'unit', label: 'Outbound Qty' },
        { key: 'act', label: 'Tracking No' },
        { key: 'worn', label: 'Batch No' },
      ],
      jobTrack: {
        page: '1',
        size: '20',
        startEventTime: null,
        endEventTime: null,
        statusCode: null,
        orderBy: 'id',
        orderMole: '0',
        tkIDs: '',
      },
      baseInfo: {
        invoiceNo: '',
        status: null,
        createdAt: '',
        quantity: '',
        totalAmount: '',
      },
      account: {
        billToName: '',
        billToCompany: '',
        billToAddress1: '',
        billToCity: '',
        billToProvince: '',
        billToPostal: '',
        billToCountryCode: '',
        accountNumber: '',
        netDays: '',
        remitToName: '',
        remitToCity: '',
        remitToPostal: '',
        remitToCountryCode: '',
      },
      tags: [],
      condition: {
        page: '1',
        size: '15',
        orderBy: 'id',
        // orderMole: 0,
        status: null,
        invoiceType: null,
        beginDate: null,
        endDate: null,
        refNo: null,
        invoiceNo: null,
      },
    }
  },
  created() {},
  mounted() {
    // this.getDetails()
    // this.statusTrack()
  },
  methods: {
    // base info
    getDetails() {
      this.condition.invoiceNo = this.$route.params.id
      this.$http.post('invoiceDetail/search', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data.content
        this.items = info
        const result = res.data.data
        this.page = result.page
        this.size = result.size
        this.totalSize = result.totalSize
        this.totalPage = result.totalPage
      })
    },
    // status tracking
    statusTrack() {
    },
    // 根据TKID查询
    handleSearch() {
      if (this.searchPID.trim() !== '') {
        this.condition.refNo = this.searchPID.trim()
        this.getDetails()
      } else {
        this.condition.refNo = null
        this.getDetails()
      }
    },
    handleChangePage(page) {
      console.log(page)
      this.condition.page = page
      this.getDetails()
    },
    handlePageChange(active) {
      console.log(active)
      this.condition.size = active
      this.getDetails()
    },
  },
}
</script>
<style scoped>
.ll-0{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.mb-0{
  font-size: 12px;
}
.ll-1{
  display: flex;
  width: 100%;
}
.ll-title{
  width: 70%;
  /* margin: auto; */
}
.ll-input{
  width: 30%;
}
.ll-p2{
  padding: 0.5rem;
}
.ll-c{
  margin-bottom: 0;
}
.ll-c .card-header{
  font-weight: 500;
  font-size: 1.285rem;
}
.ll-c .card-body .card-title{
  font-weight: normal;
  /* font-size: 14px; */
}
.d-block{
  font-size: 14px;
}
.ll-h .col{
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
  font-weight: bold;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100%;
}
.ll-btn .btn-danger, .ll-btn .btn-primary{
  width: 30%;
}
.ll-3{
  margin-top: 10px;
}
.ll-3 div{
  padding: 0;
}
.g-label{
  justify-content: space-between !important;
}
.ll-4{
  height: 80px;
  background-color: unset;
  box-shadow: unset;
}
.ll-4 .card-header{
  font-weight: normal;
  font-size: 12px;
}
.ll-4 .card-title{
  font-weight: 500 !important;
  font-size: 16px;
}
.ll-bform{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-6{
  margin-bottom: 0px;
  overflow-y: auto;
}
.ll-height{
    height: 290px;
}
.ll-height1{
    height: 400px;
}
.ll-trim{
  position: relative;
  left: 40px;
}
.ll-trim1{
  position: relative;
  left: 5px;
}
.ll-badge{
  padding: 5px;
  margin: 5px;
}
.ll-cb{
  margin: 1.5rem;
  margin-top: 0;
}
.ll-form {
  display: flex;
  justify-content: space-between;
}
.ll-tagBox{
  padding: 2rem 0.5rem;
}
.ll-mrg{
  margin-bottom: 10px;
}
.ll-bfBox{
  width: 100%;
  padding-bottom: 10px;
}
.ll-bfBox .ll-label{
  width: 30%;
}
.ll-bfBox .ll-inpBox{
  width: 70%;
}
.ll-imgBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ll-imgSpan{
    display: block;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}
.ll-box{
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 6px;
}
.ll-box .ll-lab{
  font-weight: 500;
}
</style>
<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: #fff;
}
[dir] .table thead th{
  border: none;
  padding: 5px;
}
.d-block{
  font-size: 14px;
}
[dir] .card .card-title {
  margin-bottom: 0.5rem;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 28%;
}
.d-block{
  font-size: 12px;
}
.b-sidebar-header strong{
  width: 100%;
  text-align: center;
}
.table td {
    padding: 0.72rem;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
